type Props = {
  noLabel?: boolean;
} & React.HTMLProps<HTMLDivElement>;

/**
 * Renders the skeleton of an `<Input />` from our `ui` package.
 * Use this skeleton component to render the skeleton of a form.
 *
 * @param noLabel Don't render the label skeleton.
 */
const SkeletonInput = ({ noLabel = false, ...divProps }: Props) => {
  return (
    <div {...divProps}>
      {!noLabel && <div className="bg-gray-200 rounded h-[16px] w-28" />}
      <div className="bg-gray-200 rounded h-[39px] mt-[5px] w-full" />
    </div>
  );
};

export default SkeletonInput;
