import Head from "next/head";
import React from "react";

type Props = {
  url: string;
  title: string;
  description: string;
  imageUrl?: string;
};

const FacebookMeta = ({ url, title, description, imageUrl }: Props) => {
  return (
    <Head>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
    </Head>
  );
};

export default FacebookMeta;
