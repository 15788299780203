import { isFunction, isNumber } from "@clubofcode/shared";
import { motion, type Variants } from "framer-motion";
import React, { useRef } from "react";
import { useMeasure } from "./hooks";

type Props = {
  isVisible: boolean;
  ease?: string;
  duration?: number;
  variants?: Variants;
  children: React.ReactNode;
};

const defaultVariants: Variants = {
  open: {
    opacity: 1,
    height: "auto"
  },
  collapsed: { opacity: 0, height: 0 }
};

// Source: https://codesandbox.io/s/animate-height-framer-motion-yn59l?file=/src/AnimateHeight.js
function AnimateHeight({
  duration,
  ease = "easeOut",
  variants = defaultVariants,
  isVisible,
  children
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const bounds = useMeasure(ref);

  return (
    <motion.div
      className="overflow-hidden"
      initial={isVisible ? "open" : "collapsed"}
      animate={isVisible ? "open" : "collapsed"}
      inherit={false}
      variants={variants}
      transition={{
        ease,
        duration: isNumber(duration)
          ? duration
          : getAutoHeightDuration(bounds.height) / 1000
      }}
    >
      {isFunction(children) ? children(ref) : <div ref={ref}>{children}</div>}
    </motion.div>
  );
}

/**
 * Get the duration of the animation depending upon
 * the height provided.
 * @param height The height of the container.
 */
function getAutoHeightDuration(height?: number) {
  if (!height) return 0;
  const constant = height / 36;
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

export default AnimateHeight;
