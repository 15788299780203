import clsx from "clsx";
import { useMemo } from "react";

type Props = {
  as?: "div" | "li";
  children: React.ReactNode;
  times?: number;
  className?: string;
  disableAnimation?: boolean;
};

/**
 * A skeleton resembles the structure of a component as a placeholder.
 * Use this component as a placerholder for components that are in a loading state.
 * @param props.as The tag to render this component as (default: "div").
 * @param props.times The number of times to repeat this component (default: 1).
 */
const Skeleton = ({
  as: Component = "div",
  times = 1,
  children,
  className,
  disableAnimation = false,
}: Props) => {
  const repeat = useMemo(() => new Array(times).fill(0), [times]);

  return (
    <>
      {repeat.map((_, i) => (
        <Component
          key={i}
          className={clsx(!disableAnimation && "animate-pulse", className)}
        >
          {children}
        </Component>
      ))}
    </>
  );
};

export default Skeleton;
