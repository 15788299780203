import Head from "next/head";
import React from "react";

type Props = { title: string; description: string };

/**
 * Sets the title and the description of an HTML document.
 */
const SEOMeta = ({ title, description }: Props) => {
  return (
    <Head>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </Head>
  );
};

export default SEOMeta;
