import React, { useState, useEffect, useCallback } from "react";

function useCallbackRef<T>() {
  const [ref, setRef] = useState<React.MutableRefObject<T | null> | null>(null);
  const fn = useCallback((node: React.MutableRefObject<T | null>) => {
    setRef(node);
  }, []);

  return [ref, fn] as const;
}

export function useMeasure<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>
) {
  const [element, attachRef] = useCallbackRef<T>();
  const [bounds, setBounds] = useState<{ height?: number }>({});

  useEffect(() => {
    function onResize([entry]: ResizeObserverEntry[]) {
      setBounds({ height: entry.contentRect.height });
    }

    const observer = new ResizeObserver(onResize);

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element]);

  useEffect(() => {
    attachRef(ref);
  }, [attachRef, ref]);

  return bounds;
}
