import { motion, AnimatePresence } from "framer-motion";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const noOpacity = { opacity: 0 };
const fullOpacity = { opacity: 1 };

const AnimatePresenceOpacity = ({ className, children }: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={noOpacity}
        animate={fullOpacity}
        exit={noOpacity}
        className={className}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default AnimatePresenceOpacity;
