import React from "react";
import Head from "next/head";

type Props = { title: string; description: string; imageUrl?: string };

/**
 * Sets meta tags to improve the display of information
 * if shared via Twitter.
 */
const TwitterMeta = ({ title, description, imageUrl }: Props) => {
  return (
    <Head>
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@clubofcode" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Head>
  );
};

export default TwitterMeta;
