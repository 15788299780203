import React from "react";
import clsx from "clsx";

import {
  SearchJobPostings,
  SearchJobPostingsOfPublicCompany,
} from "~/types/graphql.generated";

import { AppLink } from "../Link";
import { JobSearchResultInternal } from "./components";

type Props = {
  data?: SearchJobPostings;
} & React.HTMLAttributes<HTMLDivElement>;

const JobSearchResults = ({ data, className, ...divProps }: Props) => {
  return (
    <div className={clsx("flex flex-col gap-y-6", className)} {...divProps}>
      {data?.searchJobPostings?.nodes.map((node) => {
        if (!node) return null;

        if (node.__typename === "SearchJobPostingInternal") {
          return <JobSearchResultInternal key={node.id} job={node} />;
        }

        return null;
      })}
      <p className="py-2 sm:py-4 -mx-2 sm:mx-0 text-sm sm:text-base text-center bg-gray-200 lg:rounded">
        <AppLink
          to="/signin"
          className="font-medium text-indigo-500 hover:underline"
        >
          Jetzt anmelden
        </AppLink>
        &nbsp;und über 3.000 Entwickler-Jobs entdecken.
      </p>
    </div>
  );
};

export default JobSearchResults;
