import React from "react";
import { Container, Logo, Title } from "@clubofcode/ui";
import { Link } from "../Link";
import HeaderLoginButton from "./HeaderLoginButton";

const Header = () => {
  return (
    <header className="mb-8">
      <Container className="pt-4 sm:pt-12">
        <div className="flex justify-between items-center">
          <Title as="h1">
            <Link to="/" className="block">
              <div className="hidden sm:block">
                <Logo gradientId="logo-with_text" height={48} withText />
              </div>
              <div className="block sm:hidden">
                <Logo gradientId="logo-no_text" height={48} withText={false} />
              </div>
            </Link>
          </Title>
          <div className="flex items-center gap-x-12">
            <Link
              to="/companies"
              className="block p-1 text-lg font-medium hover:underline"
            >
              Für Unternehmen
            </Link>
            <HeaderLoginButton />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
