import React, { useRef, useState } from "react";
import Head from "next/head";
import {
  CheckIcon,
  Container,
  Divider,
  Logo,
  Title,
  Render,
} from "@clubofcode/ui";

import { AppLink, Link } from "../components/Link";
import { HeaderLoginButton } from "../components/Header";
import { FacebookMeta, SEOMeta, TwitterMeta } from "../components/Meta";
import { JobSearchResults } from "../components/JobSearchResults";
import { useSearchJobsQuery } from "../utils";
import { Skeleton } from "../components/Skeleton";
import { JobSearchInput } from "../components/JobSearchInput";
import {
  AnimateHeight,
  AnimatePresenceOpacity,
} from "../components/AnimateHeight";

const links = [
  ["Impressum", "/legal/imprint"],
  ["Datenschutz", "/legal/privacy/website"],
  ["Rechtliches", "/legal"],
  ["Über uns", "/about-us"],
];

const features = [
  "Anonyme Ein-Klick-Bewerbung",
  "Automatisiertes Matching",
  "Über 900 Unternehmen",
];

const IndexPage = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [thisYear] = useState(() => new Date().getFullYear());
  const [query, setQuery] = useState("");
  const [hasTriggeredSearch, setHasTriggeredSearch] = useState(false);
  const { data, isLoading } = useSearchJobsQuery({
    query,
    pagination: { limit: 15, offset: 0 },
  });

  return (
    <div className="relative min-h-screen flex flex-col">
      <SEOMeta
        title="Finde Deinen Entwickler-Job mit Club of Code."
        description="Importiere Deinen Lebenslauf. Bewirb Dich mit nur einem Klick. Erhalte Angebote und Rückmeldungen noch am selben Tag. Anonym und unabhängig."
      />
      <TwitterMeta
        title="Finde Deinen Entwickler-Job. Unabhängig und anonym."
        description="Gehe den nächsten Schritt in Deiner Entwickler-Karriere. Importiere Deinen LinkedIn-Lebenslauf. Bewirb Dich mit nur einem Klick. Vereinbare Deine ersten Interviews noch heute."
      />
      <FacebookMeta
        url="https://clubofcode.io/"
        title="Finde Deinen Entwickler-Job. Unabhängig und anonym."
        description="Gehe den nächsten Schritt in Deiner Entwickler-Karriere. Importiere Deinen LinkedIn-Lebenslauf. Bewirb Dich mit nur einem Klick. Vereinbare Deine ersten Interviews noch heute."
      />
      <Head>
        <title>Finde Deinen Entwickler-Job mit Club of Code</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Container className="pt-4 sm:pt-12 flex-grow">
        <div className="flex justify-between items-center">
          <Title as="h1">
            <Link to="/" className="block">
              <div className="hidden sm:block">
                <Logo gradientId="logo-with_text" height={48} withText />
              </div>
              <div className="block sm:hidden">
                <Logo gradientId="logo-no_text" height={48} withText={false} />
              </div>
            </Link>
          </Title>
          <div className="flex items-center gap-x-4 sm:gap-x-6 md:gap-x-8 lg:gap-x-12">
            <Link
              to="/companies"
              className="block p-1 sm:text-lg font-medium hover:underline"
            >
              Für Unternehmen
            </Link>
            <HeaderLoginButton />
          </div>
        </div>
        <div className="mt-2 sm:mt-4 lg:mt-8 ">
          <AnimateHeight
            isVisible={query.trim().length < 1 && !hasTriggeredSearch}
          >
            <div className="relative">
              <Title
                as="h2"
                className="font-black tracking-tight text-3xl sm:text-5xl md:text-[4rem] lg:text-[4.75rem] leading-tight sm:leading-tight md:leading-tight lg:leading-tight"
              >
                3.000 Entwickler-Jobs.
                <br className="block" />
                <span className="text-gradient bg-gradient-to-r from-accent to-red-600">
                  Eine Suche.
                </span>
              </Title>
            </div>
          </AnimateHeight>
        </div>
        <div className="sticky top-0 z-10 bg-white pt-1 sm:pt-4 pb-1 lg:pb-2">
          <JobSearchInput
            onChange={(value) => {
              if (!value.trim().length) {
                timeoutRef.current = setTimeout(() => {
                  setHasTriggeredSearch(false);
                }, 2400);
              } else if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = undefined;
              }

              if (!hasTriggeredSearch && value.trim().length) {
                setHasTriggeredSearch(true);
              }

              setQuery(value);
            }}
            value={query}
          />
          <div className="hidden lg:block absolute top-full w-full h-6 bg-gradient-to-b from-white via-white/75 to-transparent" />
        </div>
        <Render
          if={query.trim().length > 0}
          else={
            <AnimatePresenceOpacity>
              <div className="flex flex-col justify-center items-center h-full mt-[5vh] md:mt-[10vh]">
                <p className="text-center uppercase text-sm sm:text-lg font-medium text-gray-500 tracking-wider">
                  Finde Deinen Entwickler-Job bei über 900 Unternehmen.
                </p>
                <div className="w-full flex justify-center">
                  {/* 106/496px = 21.371% */}
                  <div className="relative w-[496px]">
                    <div className="relative pb-[21.371%]">
                      <img
                        alt="Companies that are using Club of Code"
                        src="/assets/companies/companies_landing_page.png?lastModifiedAt=1702681200000"
                        width="100%"
                        height="100%"
                        className="absolute object-contain w-full h-full select-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AnimatePresenceOpacity>
          }
        >
          <div className="flex flex-col-reverse lg:flex-row gap-6 mt-1 lg:mt-4">
            <Render
              if={!isLoading}
              else={
                <AnimatePresenceOpacity className="flex flex-col w-full gap-y-2 flex-grow">
                  <Skeleton times={5} className="flex flex-col gap-y-2">
                    <div className="rounded bg-gray-200 h-8 w-72" />
                    <div className="flex gap-x-4">
                      <div className="rounded bg-gray-200 h-6 w-32" />
                      <div className="rounded bg-gray-200 h-6 w-32" />
                      <div className="rounded bg-gray-200 h-6 w-32" />
                    </div>
                    <div className="flex gap-x-4">
                      <div className="rounded bg-gray-200 h-6 w-20" />
                      <div className="rounded bg-gray-200 h-6 w-16" />
                      <div className="rounded bg-gray-200 h-6 w-16" />
                      <div className="rounded bg-gray-200 h-6 w-16" />
                      <div className="rounded bg-gray-200 h-6 w-16" />
                      <div className="rounded bg-gray-200 h-6 w-16" />
                    </div>
                    <Divider className="my-1" />
                  </Skeleton>
                </AnimatePresenceOpacity>
              }
            >
              <AnimatePresenceOpacity className="flex-grow">
                <JobSearchResults data={data} className="w-full" />
              </AnimatePresenceOpacity>
            </Render>
            <div className="hidden md:flex md:justify-between md:items-center lg:block lg:sticky lg:top-24 lg:z-20 w-full lg:w-auto lg:min-w-[374px] p-2 lg:p-8 bg-gray-200 lg:rounded-xl flex-shrink-0 self-start">
              <Title
                as="h6"
                className="text-3xl text-center mb-2 font-bold hidden lg:block"
                block
              >
                Club of Code beitreten
              </Title>
              <div className="text-center lg:mt-4 lg:mb-6">
                <ul className="flex gap-x-4 lg:gap-x-0 lg:inline-block text-left font-medium lg:space-y-1 text-sm md:text-base lg:text-lg">
                  {features.map((feature) => (
                    <li key={feature}>
                      <CheckIcon
                        size={20}
                        className="inline-block text-[#44eb68] mr-1 scale-75 md:scale-100"
                        strokeWidth={3}
                      />
                      <span className="text-gray-800">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <AppLink
                to="/signup"
                className="bg-accent hover:bg-[#ff5957] block py-1 lg:py-2 px-4 text-white text-sm md:text-base lg:text-lg rounded text-center font-medium whitespace-nowrap"
              >
                Jetzt anmelden
              </AppLink>
            </div>
          </div>
        </Render>
      </Container>
      <footer>
        <ul className="w-full flex justify-center mt-6 mb-1 items-center gap-x-6 text-gray-600">
          <li>No cookies.</li>
          <li>No ads.</li>
          <li>Privacy first.</li>
        </ul>
        <div className="bg-gray-100 border-t border-gray-200">
          <div className="container mx-auto px-2 sm:px-0 sm:w-9/10 sm:flex sm:justify-between py-2">
            <div className="block text-center md:text-left mb-1 sm:mb-0">
              &#169; {thisYear} <Link to="/">Club of Code</Link> - Alle Rechte
              vorbehalten.
            </div>
            <ul className="flex justify-center gap-x-8">
              {links.map(([title, target]) => (
                <li key={target} className="">
                  <Link to={target} className="hover:underline">
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default IndexPage;
