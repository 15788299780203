import React from "react";
import { ArrowIcon, Render } from "@clubofcode/ui";
import { useLoggedInQuery } from "~/utils";
import { AppLink } from "../Link";

/**
 * When a user is signed in, we want to redirect him to the app.
 * If he's not, we want to offer him to sign up.
 */
const HeaderLoginButton = () => {
  const { data, isLoading } = useLoggedInQuery();
  const isLoggedIn = data?.isLoggedIn;

  if (isLoading) {
    return null;
  }

  return (
    <AppLink
      to={isLoggedIn ? "/" : "/signin"}
      className="btn px-4 sm:px-6 py-1 sm:py-2 font-medium border-brand text-brand hover:bg-black hover:text-white normal-case whitespace-nowrap focus:outline-brand"
    >
      <Render if={!!isLoggedIn} else="Einloggen">
        <span className="inline-flex items-center">
          Zur App
          <ArrowIcon size={18} className="ml-1" />
        </span>
      </Render>
    </AppLink>
  );
};

export default HeaderLoginButton;
