import { useEffect } from "react";
import clsx from "clsx";
import {
  CloseIcon,
  Hotkey,
  SearchIcon,
  Render,
  useWindowHotkey,
} from "@clubofcode/ui";

type Props = {
  onChange: (value: string) => void;
  value: string;
};

const JobSearchInput = ({ onChange, value }: Props) => {
  const ref = useWindowHotkey<HTMLInputElement>([
    {
      control: true,
      code: "KeyK",
      callback: (ref) => {
        ref.current?.focus();
      },
    },
    {
      code: "Escape",
      callback: (ref) => {
        if (window.document.activeElement === ref.current) {
          onChange("");
        }
      },
    },
  ]);

  useEffect(() => {
    ref.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      <input
        className={clsx(
          "pl-8 sm:pl-14 py-1 sm:py-2 lg:py-3 bg-white border-gray-400 border-2 rounded sm:rounded-lg lg:rounded-xl text-base sm:text-lg md:text-xl lg:text-2xl font-medium w-full",
          value.length ? "pr-12 sm:pr-40" : "pr-4 sm:pr-32",
        )}
        placeholder="React, Python, DevOps, Frontend, Teilzeit, Remote, ..."
        onChange={(e) => onChange(e.currentTarget.value)}
        value={value}
        ref={ref}
      />
      <div className="absolute top-0 left-0 bottom-0 flex items-center justify-center pl-2 sm:pl-4 text-gray-700 select-none pointer-events-none">
        <SearchIcon size={22} className="scale-75 sm:scale-100" />
      </div>
      <div className="h-full absolute bottom-0 right-0 mr-4 select-none pointer-events-none">
        <div className="h-full flex items-center gap-x-2">
          <Render if={!!value.length}>
            <div
              className="scale-75 sm:scale-100 flex-none bg-gray-500 hover:bg-gray-700 text-white p-[2px] rounded-full leading-none cursor-pointer pointer-events-auto"
              onClick={() => onChange("")}
            >
              <CloseIcon size={16} strokeWidth={3} />
            </div>
          </Render>
          <div className="hidden sm:block">
            <Hotkey keys={["Strg", "K"]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSearchInput;
